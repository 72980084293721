// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,  
  apiUrl: "https://maacpd.in/api/", 
  
  // apiUrl: "http://3.110.216.210:8001/api/",
  l2apiUrl: "https://maacpd.in/l2api/",
  l3apiUrl: "https://maacpd.in/l4api/",
  l4apiUrl: "https://maacpd.in/l4api/",

  apiUrlNot: "https://maacpd.in/api/",
  pdfUrl: "https://s3-ap-southeast-1.amazonaws.com/maacpd/static/pdf/",
  redirectUrl1: "http://l1.maacpd.in/#/l1/",
  redirectUrl2: "http://l2.maacpd.in/#/l2/",
  redirectUrl3: "http://l2.maacpd.in/#/l3/",

  downloadUrl: "https://maacpd.in"

};
